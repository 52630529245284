import { NavUser } from '#app/components/nav-user.tsx'
import {
	Sidebar,
	SidebarContent,
	SidebarFooter,
	SidebarHeader,
	SidebarRail,
} from '#app/components/ui/sidebar'
import { createAvatarName } from '#app/utils/avatar-name.ts'
import { ModelName } from '#app/utils/modelNames.ts'
import { models } from '#app/utils/models.ts'
import { Theme } from '#app/utils/theme.server.ts'
import { useProjectData } from '#app/utils/useProjectData.tsx'
import { useProjectsList } from '#app/utils/useProjectsList.tsx'
import { useUser } from '#app/utils/user.ts'
import { useLocation } from '@remix-run/react'
import * as React from 'react'
import { NavItems } from './nav-items'
import { ProjectSwitcher } from './projects-switcher'
import { IconName } from './ui/icon'

const internalModels: ModelName[] = [
	'persona',
	'pain',
	'feature',
	'userFlow',
	'page',
	'marketing',
	'model',
]
interface NavItem {
	icon: IconName
	inProject: boolean
	isActive: boolean
	name: string
	url: string
}

export function internalNavItems(projectId: string): NavItem[] {
	const location = useLocation()

	const items = internalModels.map((modelName) => {
		const model = models[modelName]
		const url = model.inProject ? model.listUrl(projectId) : model.listUrl()
		return {
			icon: ('icon' in model ? model.icon : 'check') as IconName,
			inProject: true,
			isActive: location.pathname === url || location.pathname.startsWith(url),
			name: model.displayNames.plural,
			url,
		}
	})

	return items
}

function CodeItem({ projectId }: { projectId: string }): NavItem {
	const location = useLocation()
	const url = `/projects/${projectId}/code`
	return {
		icon: 'code',
		inProject: false,
		isActive: location.pathname === url || location.pathname.startsWith(url),
		name: 'Code',
		url,
	}
}

const secondaryData: {
	icon: IconName
	name: string
	primary?: boolean
	url: string
}[] = [
	{ icon: 'message-square-text', name: 'Feedback', url: '/contact' },
	{ icon: 'star', name: 'Upgrade to Pro', primary: true, url: '/pricing' },
]

export function AppSidebar({
	theme,
	...props
}: React.ComponentProps<typeof Sidebar> & { theme: null | Theme }) {
	const user = useUser()
	const projects = useProjectsList()
	const { project } = useProjectData() ?? { project: null }
	const dashboardItem = {
		icon: 'home' as IconName,
		name: 'Dashboard',
		url: `/projects/${project?.id ?? ''}`,
	}

	return (
		<Sidebar collapsible="icon" {...props}>
			<SidebarHeader>
				<ProjectSwitcher projects={projects ?? []} />
			</SidebarHeader>
			<SidebarContent>
				{project && (
					<>
						<NavItems
							items={[
								dashboardItem,
								...internalNavItems(project.id),
								CodeItem({ projectId: project.id }),
							]}
						/>
						<NavItems className="mt-auto" items={secondaryData} />
					</>
				)}
			</SidebarContent>
			<SidebarFooter>
				<NavUser
					theme={theme}
					user={{ ...user, avatar: createAvatarName(user.name ?? '') }}
				/>
			</SidebarFooter>
			<SidebarRail />
		</Sidebar>
	)
}
